import type { GetServerSidePropsContext } from "next"
import { Session } from "next-auth"
import { getSession } from "next-auth/react"

export { default as Faq } from "./Faq"
export { default as OurBread } from "./OurBread"
export { default as AboutUs } from "./AboutUs"
export { default as Shell } from "./ui/Shell"
export { default as AdminShell } from "./ui/AdminShell"
export { default as OrderDetails } from "./ui/OrderDetails"
export { default as SignIn } from "./SignIn"
export { default as VerifySignInRequest } from "./VerifySignInRequest"
export { default as ProductList } from "./ProductList"

export { default as StatusPage } from "./shared/StatusPage"
export { default as Alert } from "./shared/Alert"

export { default as StockForm } from "./inventory/StockForm"

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ")
}

export const restrictLoggedInArea = async (
  context: GetServerSidePropsContext,
  callbackUrl: string,
  cb: (session: Session) => Promise<any>,
) => {
  const session = await getSession(context)

  if (!session) {
    return {
      redirect: {
        destination: `/auth/signin?callbackUrl=${callbackUrl}`,
        permanent: false,
      },
    }
  }

  return await cb(session)
}
