import Image from "next/image"
import Link from "next/link"
import Router from "next/router"

import Button from "@app/components/shared/Button"
import Container from "@app/components/shared/Container"
import FormattedDate from "@app/components/shared/FormattedDate"
import { useCart } from "@app/hooks/cart"

const CartDetails = () => {
  const { setAmountItem, cartItems, removeItem } = useCart()

  const price = cartItems.reduce((result, item) => {
    return result + item.amount * item.price
  }, 0)

  return (
    <Container>
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-0">
        <h1 className="text-3xl font-extrabold text-center tracking-tight text-gray-900 sm:text-4xl">
          Carrinho de compras
        </h1>

        <form
          onSubmit={(ev) => {
            ev.preventDefault()
            Router.push("/checkout")
          }}
          className="mt-12"
        >
          <section aria-labelledby="cart-heading">
            <h2 id="cart-heading" className="sr-only">
              Items no seu carrinho de compra
            </h2>

            <ul role="list" className="border-t border-b border-gray-200 divide-y divide-gray-200">
              {cartItems.map((item) => (
                <li key={`${item.id}-${item.batchId}`} className="flex py-6 sm:py-10">
                  <div className="relative w-24 h-24 rounded-lg sm:w-32 sm:h-32">
                    {item.imageUrl && (
                      <Image
                        src={item.imageUrl}
                        alt={item.name}
                        layout="fill"
                        objectFit="contain"
                      />
                    )}
                  </div>

                  <div className="relative ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                    <div>
                      <div className="flex justify-between sm:grid sm:grid-cols-2">
                        <div className="pr-6">
                          <h3 className="text-sm">
                            <Link href={`/product/${item.parentId}`}>
                              <a className="font-medium text-gray-700 hover:text-gray-800">
                                {item.name}
                              </a>
                            </Link>
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Fornada:
                            <FormattedDate dateString={item.batchDate} />
                          </p>
                        </div>

                        <p className="text-sm font-medium text-gray-900 text-right">
                          R${item.price.toFixed(2)}
                        </p>
                      </div>

                      <div className="mt-4 flex items-center sm:block sm:absolute sm:top-0 sm:left-1/2 sm:mt-0">
                        <label htmlFor={`quantity-${item.id}`} className="sr-only">
                          Quantidade, {item.id}
                        </label>
                        <select
                          onChange={(ev) => {
                            setAmountItem(item, Number.parseInt(ev.target.value))
                          }}
                          id={`quantity-${item.id}`}
                          name={`quantity-${item.id}`}
                          className="block max-w-full rounded-md border border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary sm:text-sm"
                          value={item.amount}
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>

                        <button
                          type="button"
                          onClick={() => {
                            removeItem(item)
                          }}
                          className="ml-4 text-sm font-medium text-secondary hover:text-primary sm:ml-0 sm:mt-3"
                        >
                          <span>Remover</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </section>

          {/* Order summary */}
          <section aria-labelledby="summary-heading" className="mt-10">
            <h2 id="summary-heading" className="sr-only">
              Resumo do pedido
            </h2>

            <div>
              <dl className="space-y-4">
                <div className="flex items-center justify-between">
                  <dt className="text-base font-medium text-gray-900">Subtotal</dt>
                  <dd className="ml-4 text-base font-medium text-gray-900">R${price.toFixed(2)}</dd>
                </div>
              </dl>
              <p className="mt-1 text-sm text-gray-500">
                Custos com entrega serão calculados no Checkout
              </p>
            </div>

            <div className="mt-10">
              <Button text="Fechar pedido" loading={false} dataTestId="cart-buy" />
            </div>

            <div className="mt-6 text-sm text-center">
              <p>
                ou{" "}
                <Link href="/">
                  <a className="text-secondary font-medium hover:text-primary">
                    Continue comprando<span aria-hidden="true"> &rarr;</span>
                  </a>
                </Link>
              </p>
            </div>
          </section>
        </form>
      </div>
    </Container>
  )
}

export default CartDetails
