import { useEffect, useState, createContext } from "react"

import {
  setAmountItem as setAmountItemImpl,
  addItem as addItemImpl,
  removeItem as removeItemImpl,
  getCart,
  Cart,
  emptyCart,
  CartItem,
  clearCart as clearCartImpl,
} from "@app/cart"

export interface CartContextData {
  addItem: (item: CartItem) => void
  removeItem: (item: CartItem) => void
  setAmountItem: (item: CartItem, amount: number) => void
  cartItems: CartItem[]
  clearCart: () => void
}

const defaultValues = {
  addItem: () => {},
  removeItem: () => {},
  setAmountItem: () => {},
  clearCart: () => {},
  cartItems: [],
}

export const CartContext = createContext<CartContextData>(defaultValues)

const CartProvider = ({ children }: { children: JSX.Element }) => {
  const [cart, setCart] = useState<Cart>(emptyCart())

  useEffect(() => {
    // SSR can't get cart from local storage
    refreshCart()
  }, [])

  const refreshCart = () => {
    setCart(getCart())
  }

  const addItem = (item: CartItem) => {
    addItemImpl(item)
    refreshCart()
  }

  const removeItem = (item: CartItem) => {
    removeItemImpl(item)
    refreshCart()
  }

  const clearCart = () => {
    clearCartImpl()
    refreshCart()
  }

  const setAmountItem = (item: CartItem, amount: number) => {
    setAmountItemImpl(item, amount)
    refreshCart()
  }

  return (
    <CartContext.Provider
      value={{ cartItems: cart.items, clearCart, setAmountItem, addItem, removeItem }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider
