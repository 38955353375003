import { Popover, Transition } from "@headlessui/react"
import Image from "next/image"
import Link from "next/link"
import Router from "next/router"
import { Fragment } from "react"

import Button from "@app/components/shared/Button"
import FormattedDate from "@app/components/shared/FormattedDate"
import ShoppingBagIcon from "@app/components/svg/bag.svg"
import { useCart } from "@app/hooks/cart"

const CartPreview = () => {
  const { cartItems } = useCart()

  const submit = (close: () => void) => (event: React.SyntheticEvent) => {
    event.preventDefault()
    close()
    Router.push("/checkout")
  }

  return (
    <Popover className="flow-root text-sm lg:relative lg:ml-8 z-10">
      <Popover.Button data-testid="cart-header" className="group -m-2 p-2 flex items-center">
        <div className="relative hover:bg-white hover:rounded-full">
          <ShoppingBagIcon className="h-20 text-black " aria-hidden="true" />
          {cartItems.length > 0 && (
            <span className="inline-block text-white font-bold w-6 h-6 absolute top-2 right-4 bg-secondary rounded-full">
              {cartItems.length}
            </span>
          )}
        </div>
        <span className="sr-only">items no carrinho</span>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Popover.Panel className="z-30 absolute top-28 inset-x-0 mt-px pb-6 bg-white shadow-lg sm:px-2 lg:top-full lg:left-auto lg:right-0 lg:mt-3 lg:-mr-1.5 lg:w-80 lg:rounded-lg lg:ring-1 lg:ring-black lg:ring-opacity-5">
          {({ close }) => (
            <>
              <h2 className="sr-only">Carrinho de compras</h2>
              {cartItems.length === 0 && (
                <div className="py-8">
                  <h2 className="text-lg font-large text-center text-gray-900">
                    Carrinho de compras vazio
                  </h2>
                </div>
              )}

              {cartItems.length > 0 && (
                <form onSubmit={submit(close)} className="max-w-2xl mx-auto px-4">
                  <ul role="list" className="divide-y divide-gray-200">
                    {cartItems.map((item) => (
                      <li key={`${item.id} - ${item.batchId}`} className="py-6 flex items-center">
                        <span className="relative flex-none w-16 h-16 rounded-md border border-gray-200">
                          {item.imageUrl && (
                            <Image
                              src={item.imageUrl}
                              alt="alternative"
                              layout="fill"
                              objectFit="contain"
                            />
                          )}
                        </span>
                        <div className="ml-4 flex-auto">
                          <h3 className="font-medium text-gray-900">
                            <a href={`/product/${item.parentId}`}>{item.name}</a>
                          </h3>
                          <p className="text-gray-500">
                            Fornada:
                            <FormattedDate
                              dateString={item.batchDate}
                              month="short"
                              weekday="short"
                            />
                          </p>
                          <p className="text-gray-500">
                            R$ {(item.amount * item.price).toFixed(2)}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>

                  <Button text="Fechar pedido" dataTestId="preview-cart-buy" loading={false} />

                  <p className="mt-6 text-center">
                    <Link href="/cart">
                      <a
                        onClick={() => close()}
                        className="text-sm font-medium text-secondary hover:text-primary"
                      >
                        Ver carrinho de compras
                      </a>
                    </Link>
                  </p>
                </form>
              )}
            </>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default CartPreview
