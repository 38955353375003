import { Stock } from "."
import { BatchId, BatchDay } from "./types"

// If we're on a sunday and batchId is sunday, the returned date is from next sunday
// If we're on a saturday and batchId is sunday, the returned date is tomorrow
export const calculateFutureDate = (batchId: BatchId, week: number = 0): Date => {
  const day = BatchDay[batchId]

  if (day === undefined) {
    throw new Error(`${batchId} not configured`)
  }

  const d = new Date()
  const daysToAdd = (day + 7 - d.getDay()) % 7 || 7
  const days = week * 7 + daysToAdd

  d.setDate(d.getDate() + days)
  return d
}

export const getLastDateByBatchId = (data: Stock[], batchId: BatchId): string | null => {
  const filteredData = data.filter((item) => item.batchId === batchId)
  if (filteredData.length == 0) {
    return null
  }

  const latestDate = filteredData.reduce((acc, curr) => {
    const currDate = new Date(curr.batchDate)
    const accDate = new Date(acc.batchDate)
    return currDate > accDate ? curr : acc
  })
  return latestDate.batchDate
}

export const weeksFromDays = (futureDate: Date, date: Date = new Date()): number => {
  const oneWeek = 7
  const diffInDays = Math.floor((futureDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24))
  const diffInWeeks = Math.floor(diffInDays / oneWeek)
  return diffInWeeks
}
