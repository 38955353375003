import isEmpty from "lodash/isEmpty"

interface FormattedDateOptions {
  dateString: string
  month?: Intl.DateTimeFormatOptions["month"]
  day?: Intl.DateTimeFormatOptions["day"]
  weekday?: Intl.DateTimeFormatOptions["weekday"]
}
export const formatDate = ({
  dateString,
  month = "short",
  day = "numeric",
  weekday = "short",
}: FormattedDateOptions) => {
  if (isEmpty(dateString)) {
    return ""
  }

  const options: Intl.DateTimeFormatOptions = {
    month,
    day,
    weekday,
  }
  const formatter = new Intl.DateTimeFormat("pt-BR", options)
  const date = new Date(dateString)
  const timezoneHours = date.getTimezoneOffset() * 60000
  date.setTime(date.getTime() + timezoneHours)
  return formatter.format(date)
}

const FormattedDate = (args: FormattedDateOptions) => {
  return <>{formatDate(args)}</>
}

export default FormattedDate
