import Image from "next/image"

import { classNames } from "."
import { BottomBorder } from "./shared/BottomBorder"
import Container from "./shared/Container"

const Paragraph = ({
  children,
  className = "",
}: {
  children: JSX.Element | string
  className?: string
}) => {
  return <p className={classNames("my-5", className)}>{children}</p>
}

const AboutUs = () => {
  return (
    <Container>
      <div className="mx-16">
        <h1 className="text-5xl text-black font-bold lg:hidden mb-2 md:mb-5 md:text-center">
          sobre nós
        </h1>
        <div className="hidden lg:block relative h-64 w-auto">
          <Image objectFit="contain" layout="fill" src="/assets/couple-big.jpg" alt="" />
          <span className="hidden md:block absolute text-white top-1/4 left-2/3 text-6xl font-bold">
            sobre <br />
            nós
            <BottomBorder />
          </span>
        </div>

        <div className="block lg:hidden relative h-32 sm:h-48 w-auto">
          <Image objectFit="contain" layout="fill" src="/assets/couple-small.jpg" alt="" />
        </div>

        <article className="mt-6 lg:mt-16 leading-relaxed text-md max-w-2xl mx-auto">
          <p className="italic text-secondary">
            Somos o Bana e a Fer. Um casal que ama servir as pessoas e que acredita que o pão de
            verdade é bom.
          </p>
          <Paragraph>
            Bana cresceu em uma família que valoriza muito a gastronomia. Sua vó - Osmira - era
            confeiteira, teve sua própria padaria, ministrava cursos, dava consultoria para empresas
            e a filha (mamãe do Bana) sempre a acompanhou. No meio disso tudo, o Bana sempre foi
            curioso e gostava de aprender com as duas.
          </Paragraph>
          <Paragraph>
            No início de 2020, profissionalmente éramos ‘apenas’ um advogado e uma pesquisadora em
            Ciência de Alimentos. Nessa época, tínhamos muita dificuldade em encontrar pães
            saudáveis no mercado e, durante essa busca, Bana descobriu a fermentação natural. Em um
            momento despretensioso em casa, ele resolveu fazer um fermento natural - levain - e
            começou a usá-lo para panificar. Deste fermento vieram os primeiros pães sourdough que
            compartilhamos com a família e amigos.
          </Paragraph>
          <Paragraph>
            Bana tomou gosto pelo desafio e começou a se aperfeiçoar. Passamos do pão clássico para
            os enriquecidos e, de forma inesperada, em dezembro do primeiro ano de aprendizados
            (2020), tivemos o nosso primeiro grande desafio: uma demanda comercial para produção de
            panettones.
          </Paragraph>
          <Paragraph>
            Até então não imaginávamos que a paixão pela fermentação natural deixaria de ser algo
            além de um &quot;hobby&quot;. Foram muitas madrugadas acordados testando panettones.
            Nossa jornada foi marcada por muito estudo, cursos com chefs italianos, noites mal
            dormidas e muita farinha pela casa. Seguimos aprendendo diariamente. O pão ensina e o
            &quot;repartir o pão&quot; realmente toca vidas, a começar pelas nossas.
          </Paragraph>
          <Paragraph>
            Hoje, desejamos que mais pessoas conheçam e tenham acesso a pães de verdade. Acreditamos
            que a boa nutrição é um direito humano e, por isso, prezamos desde o início em produzir
            pães saudáveis que fossem artesanais e de extrema qualidade.
          </Paragraph>
          <Paragraph>
            Agradecemos a você que consome nosso pão e que acredita, assim como nós, em alimentos de
            verdade.
          </Paragraph>
          <Paragraph>Junte-se a nós nessa jornada para tornar o nosso mundo mais leve!</Paragraph>

          <p className="my-5 italic leading-none text-center">
            <span className="font-bold">&quot;Até aqui o Senhor nos ajudou.&quot;</span> — 1 Samuel
            7:12b
          </p>
        </article>
      </div>
    </Container>
  )
}

export default AboutUs
