import Image from "next/image"
import Link from "next/link"

import Container from "./Container"

interface StatusPageProps {
  title: string
  subtitle: string
  body: string
}

const StatusPage = ({ title, subtitle, body }: StatusPageProps) => {
  return (
    <Container>
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <Link href="/">
              <a className="inline-flex relative h-14 w-full">
                <Image objectFit="contain" layout="fill" src="/assets/symbol.png" alt="" />
              </a>
            </Link>
          </div>
          <div className="py-8">
            <div className="text-center">
              <p className="text-sm font-semibold text-secondary uppercase tracking-wide">
                {subtitle}
              </p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                {title}
              </h1>
              <p className="mt-2 text-base text-gray-500">{body}</p>
              <div className="mt-6">
                <Link href="/">
                  <a className="text-base font-medium text-secondary hover:text-secondary">
                    Voltar para a loja <span aria-hidden="true"> &rarr;</span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Container>
  )
}

export default StatusPage
