import Image from "next/image"

import BagIcon from "@app/components/svg/bag.svg"
import BatchIcon from "@app/components/svg/batch.svg"
import FeedbackIcon from "@app/components/svg/feedback.svg"
import WaitingIcon from "@app/components/svg/waiting.svg"

import { BottomBorder } from "./shared/BottomBorder"
import Container from "./shared/Container"

const ListItem = ({ Img, children }: { Img: any; children: string }) => {
  return (
    <li className="flex flex-col items-center border border-gray-300 rounded-md p-5 m-2">
      <Img className="w-16 h-16 mr-2 text-secondary" />
      <span className="text-center text-md">{children}</span>
    </li>
  )
}

const Faq = () => {
  return (
    <Container>
      <div className="mx-16">
        <h1 className="text-5xl text-black font-bold lg:hidden mb-5 md:text-center">
          dúvidas frequentes
        </h1>
        <div className="hidden lg:block relative h-64 w-auto">
          <Image objectFit="contain" layout="fill" src="/assets/faq-img.jpg" alt="" />
          <span className="hidden md:block absolute text-white top-1/4 right-1/2 text-6xl font-bold">
            dúvidas <br />
            frequentes
            <BottomBorder classes="mt-6" />
          </span>
        </div>

        <ul className="mt-10 grid grid-cols-2 lg:grid-cols-4">
          <ListItem Img={WaitingIcon}>
            Recomendamos que realize o seu pedido com 2 (dois) dias de antecedência para garantir o
            sabor desejado
          </ListItem>

          <ListItem Img={BatchIcon}>
            Precisamos desse tempo para cuidar do fermento natural e organizar a produção.
          </ListItem>
          <ListItem Img={FeedbackIcon}>Pagamentos por PIX ou cartão de crédito.</ListItem>
          <ListItem Img={BagIcon}>
            Temos também alguns produtos a pronta-entrega, você pode verificar no dia a
            disponibilidade.
          </ListItem>
        </ul>
      </div>
    </Container>
  )
}

export default Faq
