import { SessionProvider } from "next-auth/react"
import type { AppProps } from "next/app"
import { useRouter } from "next/router"

import { Shell, AdminShell } from "@app/components"
import { CartProvider } from "@app/components/cart"

import "../styles/globals.css"

if (process.env.NEXT_PUBLIC_API_MOCKING === "enabled") {
  import("../mocks")
}

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const router = useRouter()

  const isAdmin = router.pathname.includes("admin")

  return (
    <SessionProvider session={session}>
      <CartProvider>
        <>
          {!isAdmin && (
            <Shell pagePath={router.pathname}>
              <Component {...pageProps} />
            </Shell>
          )}
          {isAdmin && (
            <AdminShell>
              <Component {...pageProps} />
            </AdminShell>
          )}
        </>
      </CartProvider>
    </SessionProvider>
  )
}

export default MyApp
