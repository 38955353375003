import { StatusPage } from "@app/components"

const VerifySignInRequest = () => {
  return (
    <StatusPage
      subtitle=""
      title="Login por E-mail"
      body="Enviamos um e-mail com um link para você se autenticar"
    />
  )
}

export default VerifySignInRequest
