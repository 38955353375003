import { Disclosure } from "@headlessui/react"

import { classNames } from ".."

const NavigationButton = ({
  name,
  href,
  current = false,
  onClick,
  className,
}: {
  name: string
  href?: string
  onClick?: () => void
  current?: boolean
  className?: string
}) => {
  const props = onClick ? { onClick } : { href }

  return (
    <Disclosure.Button
      {...props}
      key={name}
      as="a"
      className={classNames(
        current
          ? "bg-orange-50 border-primary text-secondary"
          : "border-transparent text-black hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
        "uppercase block pl-3 pr-4 py-2 border-l-4 text-base font-medium",
        className ?? "",
      )}
      aria-current={current ? "page" : undefined}
    >
      {name}
    </Disclosure.Button>
  )
}

export default NavigationButton
