import Image from "next/image"
import { useEffect, useMemo, useState } from "react"
import Countdown from "react-countdown"

import { Alert, classNames } from "@app/components"
import LogoVertical from "@app/components/svg/logo-vertical.svg"
import { PixData } from "@app/order/types"
import type { OrderDetails as OrderDetailsType } from "@app/pages/order/[id]"

import Container from "../shared/Container"
import { formatDate } from "../shared/FormattedDate"

const PixSection = ({ pixData }: { pixData: PixData }) => {
  const [visible, setVisible] = useState(true)
  const [copied, setCopied] = useState(false)

  const [clipboardError, setClipboardError] = useState<boolean>(false)
  const expirationDate = useMemo(() => new Date(pixData.expirationDate), [pixData.expirationDate])

  useEffect(() => {
    const now = new Date()
    if (expirationDate.getTime() < now.getTime()) {
      setVisible(false)
    }
  }, [setVisible, expirationDate])

  return (
    <div>
      <div className="mx-auto flex flex-col my-6 place-items-center items-center sm:w-48">
        <Countdown
          onComplete={() => {
            setVisible(false)
          }}
          className="text-lg"
          daysInHours
          date={expirationDate}
        >
          <Alert
            type="error"
            containerClassName="w-max text-lg"
            message="O Pix expirou. Por favor, faça outro pedido"
          />
        </Countdown>

        {visible && (
          <>
            <span className="relative h-48 w-full">
              <Image
                layout="fill"
                objectFit="contain"
                alt="QR Code para pagar Pix"
                src={pixData.qrCode}
              />
            </span>
            {!clipboardError && (
              <button
                type="button"
                className="w-full bg-secondary  border border-transparent rounded-md shadow-sm mt-4 py-2 px-4 text-sm font-medium text-white hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange-500"
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(pixData.link)
                    setCopied(true)
                  } catch {
                    setClipboardError(true)
                  }
                }}
              >
                <>
                  {!copied && <span>Copiar código</span>}
                  {copied && <span>Copiado</span>}
                </>
              </button>
            )}
            {clipboardError && (
              <p>
                Ocorreu um erro ao copiar o código. Por favor, copie o código abaixo manualmente:
                <br />
                {pixData.link}
              </p>
            )}
          </>
        )}
      </div>
      <div className="sm:mt-4 mt-12">
        <p>Escolha entre as duas opções para o pagamento:</p>
        <ol>
          <li>
            1. Se estiver no seu celular, copie o código de pagamento ✂️. Em seguida, abra o app do
            seu banco no celular na opção pagar com <b>&quot;PIX - Copia e cola&quot;</b> e cole o
            código 📋.
          </li>
          <li>
            2.Se estiver no seu computador, escaneie o código acima com seu celular 📲. Em seguida,
            abra o app do seu banco no celular na opção pagar com <b>&quot;PIX - QR Code&quot;</b> e
            aponte a câmera para o código 📸.
          </li>
        </ol>
      </div>
    </div>
  )
}

const Header = ({
  imageUrl,
  title,
  containerClass,
}: {
  imageUrl: string
  title: JSX.Element
  containerClass?: string
}) => {
  return (
    <div className="pt-12 bg-secondary jagged-edge-secondary flex flex-col items-center">
      <div className={classNames("relative rounded-lg mx-auto", containerClass ?? "")}>
        <Image src={imageUrl} alt="Pagamento pendente" layout="fill" objectFit="contain" />
      </div>
      <h2 className="text-white uppercase text-center text-lg mt-4">{title}</h2>
    </div>
  )
}

const Logo = () => {
  return (
    <div className="flex items-center justify-center">
      <LogoVertical className="w-32 text-secondary mr-2 fill-current" />
      <hr className="bg-secondary h-28 w-px " />
      <h3 className="text-secondary font-light w-32 text-center">pães de fermentação natural</h3>
    </div>
  )
}

const Body = ({ title, children }: { title: JSX.Element; children: JSX.Element }) => {
  return (
    <div className="mt-8 mx-8 md:mx-0">
      <h2 className="text-3xl text-secondary">{title}</h2>
      <hr className="mt-6 w-32 h-0.5 bg-gray-400" />
      <p className="text-sm mt-6">{children}</p>
    </div>
  )
}

const PendingPayment = ({ order }: { order: OrderDetailsType }) => {
  return (
    <div>
      <Header
        containerClass="h-20 w-20"
        imageUrl="/assets/waiting.gif"
        title={
          <>
            Aguardando
            <br /> pagamento
          </>
        }
      />

      <Container>
        <div className="max-w-sm mx-auto">
          <Logo />
          <Body
            title={
              <>
                Estamos <span className="font-extrabold">quase</span> lá!
              </>
            }
          >
            <>
              Seu pedido foi criado e será entregue quando o pagamento for efetuado. Só está
              faltando a transferência via Pix.
              <br />
              <br />
              <a href="https://api.whatsapp.com/send?phone=554391414584">
                Qualquer dúvida, entre em contato pelo Whatsapp
              </a>
            </>
          </Body>
          {order.pixData && <PixSection pixData={order.pixData} />}
        </div>
      </Container>
    </div>
  )
}

const CompletedOrder = ({ order }: { order: OrderDetailsType }) => {
  return (
    <div>
      <Header
        containerClass="h-32 w-32 -my-4"
        imageUrl="/assets/bag.gif"
        title={
          <>
            Seu pedido foi
            <br /> finalizado
          </>
        }
      />

      <Container>
        <div className="max-w-sm mx-auto">
          <Logo />
          <Body
            title={
              <>
                muito <span className="font-extrabold">obrigada</span>!
              </>
            }
          >
            <>
              Iremos preparar seu pão de fermentação natural e longa com muito carinho. Realizaremos
              a entrega {formatDate({ dateString: order.deliveryDate })}
              <br />
              <br />
              <a href="https://api.whatsapp.com/send?phone=554391414584">
                Qualquer dúvida, entre em contato pelo Whatsapp
              </a>
            </>
          </Body>
        </div>
      </Container>
    </div>
  )
}
const OrderDetails = ({ order }: { order: OrderDetailsType }) => {
  return (
    <>
      {order.pendingPayment && <PendingPayment order={order} />}
      {!order.pendingPayment && <CompletedOrder order={order} />}
    </>
  )
}

export default OrderDetails
