import { HTMLInputTypeAttribute } from "react"
import { FieldValues, Path, UseFormRegister } from "react-hook-form"

const Input = <T extends FieldValues>({
  inputName,
  inputType = "text",
  register,
  errorMessage,
  label,
  disabled = false,
  inputProps = {},
  required = false,
  registerProps = {},
  containerClassName = "",
}: {
  inputName: Path<T>
  inputType?: HTMLInputTypeAttribute | undefined
  register: UseFormRegister<T>
  errorMessage?: string | undefined
  label?: string
  disabled?: boolean
  inputProps?: Record<string, any>
  required?: boolean
  registerProps?: Record<string, any>
  containerClassName?: string
}) => {
  const id = inputName.replaceAll(".", "-")

  return (
    <div className={containerClassName}>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="mt-1">
        <input
          disabled={disabled}
          type={inputType}
          data-testid={id}
          id={id}
          className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-secondary focus:border-secondary disabled:bg-gray-200 sm:text-sm"
          {...register(inputName, { required, ...registerProps })}
          {...inputProps}
        />
      </div>

      {errorMessage && <p className="text-red-400">{errorMessage}</p>}
    </div>
  )
}

export default Input
