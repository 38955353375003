import { Disclosure } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { signOut, useSession } from "next-auth/react"
import Head from "next/head"

import { classNames } from "@app/components"
import Footer from "@app/components/Footer"
import { CartPreview } from "@app/components/cart"
import InstagramIcon from "@app/components/svg/instagram.svg"
import Profile from "@app/components/svg/profile.svg"
import WhatsAppIcon from "@app/components/svg/whatsapp.svg"

import SealBread from "../SealBread"
import NavigationButton from "../shared/NavigationButton"
import ProfileIcon from "./ProfileIcon"
import { Logo } from "./Shell/Logo"

const navigation = (pagePath: string) => [
  { name: "Loja", href: "/", current: pagePath == "/" },
  { name: "Nossos Pães", href: "/nosso-pao", current: pagePath === "/nosso-pao" },
  /* { name: "FAQ", href: "/faq", current: pagePath === "faq" }, */
  { name: "Sobre nós", href: "/about", current: pagePath === "/about" },
]

const secondaryNavigation = [
  {
    name: "instagram",
    component: <InstagramIcon className="text-black h-6 w-6 fill-current" />,
    href: "https://www.instagram.com/bana.pane",
  },
  {
    name: "whatsapp",
    component: <WhatsAppIcon className="text-black h-6 w-6 fill-current" />,
    href: "https://api.whatsapp.com/send?phone=554391414584",
  },
]

const loggedOutUserNavigation = [
  {
    name: "Entrar",
    href: "/auth/signin",
  },
]

const loggedInUserNavigation = [
  {
    name: "Sair",
    onClick: () => {
      signOut()
    },
  },
]

const Title = ({ height, width }: { height: string; width: string }) => {
  return (
    <div className="flex items-center">
      <Logo height={height} width={width} />
    </div>
  )
}

const PageMainNavigation = ({ pagePath }: { pagePath: string }) => {
  return (
    <div className="flex -my-px ml-6 pt-1 space-x-8">
      {navigation(pagePath).map((item) => (
        <a
          key={item.name}
          href={item.href}
          className={classNames(
            item.current
              ? "bg-white text-black border-transparent"
              : "text-black border-transparent hover:bg-white hover:text-black",
            "uppercase inline-flex items-center px-1 pt-1 border-b-2 text-md font-medium",
          )}
          aria-current={item.current ? "page" : undefined}
        >
          {item.name}
        </a>
      ))}
    </div>
  )
}

const PageSecondaryNavigation = () => {
  return (
    <div className="flex ml-12 -my-px pt-1 space-x-8">
      {secondaryNavigation.map((item) => (
        <a
          key={item.name}
          href={item.href}
          className="text-black border-transparent inline-flex items-center px-1 pt-1 border-b-2 text-md font-medium"
        >
          {item.component}
        </a>
      ))}
    </div>
  )
}

const ShellLargeMain = ({ pagePath }: { pagePath: string }) => {
  return (
    <div className="hidden lg:block">
      <div className="pt-10 px-10 flex justify-center">
        <Title height="h-16" width="w-64" />
      </div>

      <div className="flex mt-4 items-center justify-between">
        <div className="w-44 invisible"></div>
        <div className="flex mx-auto">
          <PageMainNavigation pagePath={pagePath} />
          <PageSecondaryNavigation />
        </div>
        <div className="flex items-center">
          <ProfileIcon
            loggedInNavigation={loggedInUserNavigation}
            loggedOutNavigation={loggedOutUserNavigation}
          />
          <CartPreview />
        </div>
      </div>
    </div>
  )
}

const ShellLargeOther = ({ pagePath }: { pagePath: string }) => {
  return (
    <div className="flex justify-between h-16">
      <Title height="h-12" width="w-36" />
      <div className="flex items-center">
        <PageMainNavigation pagePath={pagePath} />
        <PageSecondaryNavigation />
      </div>
      <div className="ml-6 flex items-center">
        <ProfileIcon
          loggedInNavigation={loggedInUserNavigation}
          loggedOutNavigation={loggedOutUserNavigation}
        />
        <CartPreview />
      </div>
    </div>
  )
}

const ShellLarge = ({ pagePath }: { pagePath: string }) => {
  return (
    <div className="hidden lg:block">
      {pagePath !== "/" && <ShellLargeOther pagePath={pagePath} />}
      {pagePath === "/" && <ShellLargeMain pagePath={pagePath} />}
    </div>
  )
}

const ShellMedium = ({
  pagePath,
  open,
  loginStatus,
}: {
  pagePath: string
  open: boolean
  loginStatus: string
}) => {
  const LogoComponent =
    pagePath === "/" ? (
      <Logo withText={false} height="h-10" width="w-20" />
    ) : (
      <Logo height="h-12" width="w-36" />
    )
  return (
    <div className="lg:hidden py-2 lg:py-6 md:px-6">
      <div className="-mr-2 flex items-center justify-between">
        <div className="bg-primary justify-start w-1/5 inline-flex items-center">
          <Disclosure.Button className="p-2 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            <span className="sr-only">Open main menu</span>
            {open ? (
              <XIcon className="block h-8 w-8" aria-hidden="true" />
            ) : (
              <MenuIcon className="block h-8 w-8" aria-hidden="true" />
            )}
          </Disclosure.Button>
        </div>

        <div className="mx-auto">{LogoComponent}</div>

        <div className="ml-6 w-1/5 justify-end flex items-center">
          <CartPreview />
        </div>
      </div>

      <Disclosure.Panel className="lg:hidden">
        <>
          <div className="pt-2 pb-3 space-y-1">
            {navigation(pagePath).map((item) => (
              <NavigationButton
                key={item.name}
                name={item.name}
                href={item.href}
                current={item.current}
              />
            ))}
            <div className="">
              <h2 className="border-t border-gray-200 mt-8 pt-8 flex items-center border-gray-200 text-xl uppercase">
                <Profile className="inline h-12" />
                Suas informações
              </h2>
              {loginStatus === "unauthenticated" &&
                loggedOutUserNavigation.map((item) => (
                  <NavigationButton key={item.name} name={item.name} href={item.href} />
                ))}
              {loginStatus === "authenticated" &&
                loggedInUserNavigation.map((item) => (
                  <NavigationButton key={item.name} name={item.name} onClick={item.onClick} />
                ))}
            </div>
          </div>
        </>
      </Disclosure.Panel>
    </div>
  )
}

const StepItem = ({ index, children }: { index: number; children: JSX.Element }) => {
  return (
    <li className="ml-2 md:ml-4 text-lg md:text-xl flex p-1 md:p-2">
      <span className="text-4xl md:text-5xl font-extrabold -mt-1 md:-mt-3">{index}</span>
      <div className="ml-1 lg:ml-2 md:text-lg text-base leading-none md:leading-none">
        {children}
      </div>
    </li>
  )
}
const HomePresentation = ({ pagePath }: { pagePath: string }) => {
  if (pagePath !== "/") {
    return null
  }

  return (
    <div className="bg-secondary mt-0 lg:pt-16 text-center lg:jagged-edge-secondary">
      <div className="block lg:flex lg:justify-center lg:mx-auto lg:justify-evenly max-w-5xl">
        <div className="block text-black lg:text-white bg-primary lg:bg-secondary jagged-edge lg:jagged-edge-none lg:flex lg:flex-col lg:items-center">
          <h2 className="text-2xl md:text-3xl lg:text-4xl">
            <span className="block lg:inline font-extrabold">Bem-vindo à</span> Bana Pane
          </h2>
          <p className="text-xl lg:text-2xl font-light">• pães de fermentação natural •</p>
        </div>
        <div className="mt-10 text-white jagged-edge-secondary lg:jagged-edge-none lg:mt-0 bg-secondary flex flex-col items-center italic">
          <p className="text-xl md:text-2xl lg:text-3xl">
            <span className="font-extrabold">1, 2, 3!</span>
            <span className="text-lg md:text-xl lg:text-2xl"> E seu pedido será feito!</span>
          </p>
          <ul className="flex items-center justify-center lg:bg-black lg:text-white my-1 md:my-4">
            <StepItem index={1}>
              <p>
                <span className="font-bold">escolha</span>
                <br />
                seu pão
              </p>
            </StepItem>
            <StepItem index={2}>
              <p>
                <span className="font-bold">selecione</span>
                <br />a fornada
              </p>
            </StepItem>
            <StepItem index={3}>
              <p>
                faça o <br /> <span className="font-bold">pagamento</span>
              </p>
            </StepItem>
          </ul>
        </div>
      </div>
    </div>
  )
}

const Shell = ({ children, pagePath }: { children: JSX.Element; pagePath: string }) => {
  const { status: loginStatus } = useSession()

  const className = pagePath === "/" ? "" : "jagged-edge"

  return (
    <>
      <Head>
        <title>Bana Pane</title>
      </Head>
      <div className="min-h-full">
        <SealBread pagePath={pagePath} />

        <div className="bg-primary">
          <Disclosure
            as="nav"
            className={classNames(
              className,
              "lg:pt-5 lg:border-b lg:border-gray-200 lg:jagged-edge z-10",
            )}
          >
            {({ open }) => (
              <>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <ShellLarge pagePath={pagePath} />
                  <ShellMedium loginStatus={loginStatus} pagePath={pagePath} open={open} />
                </div>
              </>
            )}
          </Disclosure>
          <HomePresentation pagePath={pagePath} />
        </div>

        {children}

        <Footer />
      </div>
    </>
  )
}

export default Shell
