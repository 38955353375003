import Image from "next/image"

import { BottomBorder } from "./shared/BottomBorder"
import Container from "./shared/Container"

const ListItem = ({ children }: { children: string }) => {
  return <li className="flex items-center bg-primary p-2 m-1 h-14">{children}</li>
}

const OurBread = () => {
  return (
    <Container>
      <div className="mx-16 md:max-w-2xl md:mx-auto">
        <div className="relative h-64 w-auto">
          <Image objectFit="contain" layout="fill" src="/assets/our-bread.jpg" alt="" />
        </div>
        {/* TODO: Put this in the header for mobile */}
        <div>
          <h1 className="text-5xl font-bold">nossos pães</h1>
          <BottomBorder classes="mt-6 w-36" />
        </div>

        <article className="mt-3 lg:mt-10 leading-relaxed text-md max-w-2xl mx-auto">
          <p className="mt-10 mb-5">
            Todos os nossos pães são produzidos com farinhas orgânicas, livres de aditivos químicos
            e fermentados lentamente. Pães de verdade, com fermento natural, como eram feitos
            antigamente.
          </p>
          <p className="font-bold mb-2 md:mb-6 mt-5">
            Reunimos os principais benefícios associados à fermentação natural e longa:
          </p>

          <ul className="grid grid-cols-1 md:grid-cols-2 font-bold text-sm">
            <ListItem>Digestão facilitada</ListItem>
            <ListItem>Menor índice glicêmico que o pão convencional</ListItem>
            <ListItem>Vida útil prolongada</ListItem>
            <ListItem>Aumento da biodisponibilidade mineral</ListItem>
            <ListItem>Sabor e aroma únicos</ListItem>
            <ListItem>Degradação dos fatores antinutricionais presentes na farinha</ListItem>
          </ul>
          <p className="font-bold mt-5">
            Oferecemos a melhor experiência em termos de sabor, nutrientes e digestibilidade.
          </p>
          <p>É uma honra e alegria estar à mesa com vocês!</p>
        </article>
      </div>
    </Container>
  )
}

export default OurBread
