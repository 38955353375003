import { XCircleIcon, ExclamationIcon } from "@heroicons/react/solid"

import { classNames } from ".."

const Alert = ({
  message,
  containerClassName = "",
  type = "alert",
  body = null,
}: {
  message: string
  containerClassName?: string
  type?: "alert" | "error"
  body?: string | null
}) => {
  let Icon = type === "error" ? XCircleIcon : ExclamationIcon
  const background = type === "error" ? "bg-red-50" : "bg-yellow-50"
  const textNormal = type === "error" ? "text-red-400" : "text-yellow-400"
  const textBold = type === "error" ? "text-red-800" : "text-yellow-800"

  return (
    <div className={classNames(`rounded-md p-4`, background, containerClassName)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={classNames(`h-5 w-5`, textNormal)} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className={classNames("text-lg font-medium", textBold)}>{message}</h3>

          {body && (
            <div className={classNames("mt-2 text-sm", textBold)}>
              <p dangerouslySetInnerHTML={{ __html: body }} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Alert
