import { classNames } from ".."

export const StepInfo = ({
  index,
  vertical = false,
  children,
}: {
  index: number
  vertical?: boolean
  children: string
}) => {
  const verticalClass = vertical ? "lg:vertical" : ""

  return (
    <h3 className={classNames(verticalClass, "text-center uppercase")}>
      <span className="inline-block rounded-full bg-black w-6 h-6 text-white text-center mr-2 lg:mb-2">
        {index}
      </span>
      {children}
    </h3>
  )
}
