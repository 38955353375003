import { MouseEventHandler } from "react"

const Button = ({
  dataTestId,
  loading,
  disabled = false,
  text,
  type = "submit",
  onClick = null,
}: {
  dataTestId: string
  loading: boolean
  disabled?: boolean
  text: string
  type?: "submit" | "reset" | "button" | undefined
  onClick?: MouseEventHandler | null
}) => {
  const attrs = onClick !== null ? { onClick } : {}

  return (
    <button
      type={type}
      disabled={loading || disabled}
      data-testid={dataTestId}
      className="w-full flex justify-center bg-secondary border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-primary hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange-500 disabled:bg-gray-300 disabled:text-gray-700"
      {...attrs}
    >
      {loading && (
        <>
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Processando...
        </>
      )}
      {!loading && text}
    </button>
  )
}

export default Button
