import { Popover, Transition } from "@headlessui/react"
import { useSession } from "next-auth/react"
import { Fragment } from "react"

import Profile from "@app/components/svg/profile.svg"

import NavigationButton from "../shared/NavigationButton"

interface Navigation {
  name: string
  onClick?: () => void
  href?: string
}
const ProfileIcon = ({
  loggedInNavigation,
  loggedOutNavigation,
}: {
  loggedInNavigation: Navigation[]
  loggedOutNavigation: Navigation[]
}) => {
  const { status: loginStatus } = useSession()

  const buttonClassName = "cursor-pointer border-b-4 border-primary py-4 border-l-0"
  return (
    <Popover className="-mr-12 flow-root text-sm lg:relative lg:ml-8 z-10">
      <Popover.Button data-testid="cart-header" className="group -m-2 p-2 flex items-center">
        <div className="relative hover:bg-white hover:rounded-full">
          <Profile className="h-20 text-black " aria-hidden="true" />
        </div>
        <span className="sr-only">informações do usuário</span>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Popover.Panel className="z-30 absolute top-28 inset-x-0 mt-px pb-6 bg-white shadow-lg sm:px-2 lg:top-full lg:left-auto lg:right-0 lg:mt-3 lg:-mr-1.5 lg:w-60 lg:rounded-lg lg:ring-1 lg:ring-black lg:ring-opacity-5">
          {({ close }) => (
            <div className="uppercase p-8 text-center">
              {loginStatus === "unauthenticated" &&
                loggedOutNavigation.map((item) => (
                  <NavigationButton
                    key={item.name}
                    className={buttonClassName}
                    name={item.name}
                    href={item.href}
                  />
                ))}
              {loginStatus === "authenticated" &&
                loggedInNavigation.map((item) => (
                  <NavigationButton
                    key={item.name}
                    className={buttonClassName}
                    name={item.name}
                    onClick={item.onClick}
                  />
                ))}
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default ProfileIcon
