import { useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"

import { calculateFutureDate, getLastDateByBatchId, weeksFromDays } from "@app/inventory/date"
import { BatchDay, BatchId, StockSituation } from "@app/inventory/types"

import { classNames } from ".."
import AdminButton from "../admin/AdminButton"
import Input from "../shared/Input"

const SyncStock = ({
  stock,
  setStock,
}: {
  stock: StockSituation
  setStock: (stock: StockSituation) => void
}) => {
  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
    getValues,
  } = useForm<StockSituation>({
    defaultValues: { ...stock },
  })

  const { append, fields } = useFieldArray({
    keyName: "formId",
    control,
    name: "stocks",
  })

  const validateDaysOfWeek = (value: BatchId[]) => {
    return value.every((v: BatchId) => v in BatchDay) || "Dia da semana inválido"
  }

  const onSubmit = async (data: StockSituation) => {
    const response = await fetch("/api/admin/batches", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })

    await response.json()
    setStock(data)
  }

  return (
    <div className="py-12">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mx-auto my-4 max-w-sm">
          <Input<StockSituation>
            label="Fornadas"
            register={register}
            required
            inputName={"allowedBatches"}
            registerProps={{
              setValueAs: (v: BatchId[] | string) => {
                // At first, it's an array
                if (Array.isArray(v)) return v

                return v.split(",")
              },
              validate: validateDaysOfWeek,
            }}
            errorMessage={(errors?.allowedBatches as any)?.message}
          />
        </div>

        {fields.map((item, index) => (
          <div className="flex justify-center" key={item.formId}>
            <Input<StockSituation>
              register={register}
              required
              disabled
              inputName={`stocks.${index}.batchDate`}
              containerClassName="mx-2"
            />

            <Input<StockSituation>
              register={register}
              disabled
              inputName={`stocks.${index}.batchId`}
              containerClassName="mx-2"
            />

            <Input<StockSituation>
              register={register}
              required
              inputName={`stocks.${index}.balance`}
              inputType="number"
              registerProps={{ numberAsValue: true }}
              containerClassName="mx-2"
            />
          </div>
        ))}

        <div className="my-8 flex justify-center items-center">
          <div className="mx-4">
            <AdminButton
              type="button"
              onClick={() => {
                const { stocks, allowedBatches } = getValues()
                allowedBatches.forEach((batch) => {
                  const lastDate = getLastDateByBatchId(stocks, batch)

                  let index = 0
                  if (lastDate) {
                    index = weeksFromDays(new Date(lastDate)) + 1
                  }

                  const nextDate = calculateFutureDate(batch, index)
                  append({
                    batchDate: nextDate.toISOString().substring(0, 10),
                    batchId: batch,
                    balance: 0,
                  })
                })
              }}
              primary={false}
              text="Adicionar"
            />
          </div>

          <AdminButton text="Sincronizar" />
        </div>
      </form>
    </div>
  )
}

const tabs = [
  { name: "Overview", href: "#", count: 4, current: true },
  { name: "Activity", href: "#", current: false },
  { name: "Settings", href: "#", current: false },
  { name: "Collaborators", href: "#", current: false },
  { name: "Notifications", href: "#", current: false },
]

const StockForm = ({
  stocks: stocksProps,
  sku = "pao",
}: {
  stocks: StockSituation[]
  sku?: string
}) => {
  const [currentStock, setCurrentStock] = useState(stocksProps.find((s) => s.productSku === sku))
  const [stocks, setStocks] = useState(stocksProps)

  if (!currentStock) {
    return <h1>Estoque não encontrado</h1>
  }

  const setStock = (stock: StockSituation) => {
    setStocks(stocks.map((s) => (s.productSku !== stock.productSku ? s : stock)))
  }

  return (
    <div>
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex justify-center space-x-8" aria-label="Tabs">
          {stocks.map((stock) => (
            <a
              key={stock.productSku}
              onClick={() => {
                setCurrentStock(stock)
              }}
              className={classNames(
                stock.productSku === currentStock.productSku
                  ? "border-primary text-secondary"
                  : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                "flex cursor-pointer whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium",
              )}
              aria-current={stock === currentStock ? "page" : undefined}
            >
              {stock.productSku}
              <span
                className={classNames(
                  stock === currentStock
                    ? "bg-primary text-secondary"
                    : "bg-gray-100 text-gray-900",
                  "ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block",
                )}
              >
                {stock.stocks.length}
              </span>
            </a>
          ))}
        </nav>
      </div>

      <SyncStock setStock={setStock} key={currentStock.productSku} stock={currentStock} />
    </div>
  )
}

export default StockForm
