import Image from "next/image"

import Container from "@app/components/shared/Container"
import { StepInfo } from "@app/components/shared/StepInfo"
import { Product } from "@app/inventory"

const orderProducts = ["pao", "integral", "brioche", "burguer", "babka", "fatia", "colomba", "pane"]

const sortProducts = (products: Product[]) =>
  products.sort((prod1, prod2) => {
    const index1 = orderProducts.findIndex((p) => p === prod1.sku)
    const index2 = orderProducts.findIndex((p) => p === prod2.sku)
    if (index1 < 0) {
      return 1
    }

    if (index2 < 0) {
      return -1
    }

    return index1 - index2
  })

const ProductList = ({ products }: { products: Product[] }) => {
  return (
    <Container>
      <div className="-mt-2 bg-white">
        <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="sr-only">Products</h2>

          <div className="block lg:flex">
            <div className="mt-4 mb-8 lg:mt-0 lg:mb-0 lg:block lg:self-start lg:sticky lg:top-4 lg:transform lg:rotate-180 lg:mr-10">
              <StepInfo vertical={true} index={1}>
                Escolha seu Pão
              </StepInfo>
            </div>

            <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
              {sortProducts(products).map((product) => (
                <div
                  key={product.id}
                  className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden"
                >
                  <div className="relative aspect-w-3 aspect-h-3 bg-white-200 group-hover:opacity-75 h-60">
                    {product.images[0] && (
                      <Image
                        src={product.images[0]}
                        alt={product.name}
                        width="100%"
                        height="100%"
                        layout="responsive"
                        className="object-center object-cover"
                      />
                    )}
                  </div>
                  <div className="flex-1 p-4 space-y-2 flex flex-col">
                    <h3 className="text-xl font-extrabold">
                      <a href={`/product/${product.id}`}>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {product.name}
                      </a>
                    </h3>
                    <div className="flex-1 flex flex-col justify-start">
                      <p className="text-base font-medium italic text-gray-500">
                        {product.variations.map((v) => v.value).join(", ")}
                      </p>
                      <p className="text-base font-medium text-gray-900">
                        A partir de R$ {Math.min(...product.variations.map((v) => v.price))}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default ProductList
