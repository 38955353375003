import InstagramIcon from "@app/components/svg/instagram.svg"
import WhatsAppIcon from "@app/components/svg/whatsapp.svg"

const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          <a href="https://api.whatsapp.com/send?phone=554391414584">
            <span className="sr-only">Whatsapp</span>
            <WhatsAppIcon className="text-gray-500 hover:text-gray-900 h-6 w-6 fill-current" />
          </a>

          <a href="https://www.instagram.com/bana.pane">
            <span className="sr-only">Instagram</span>
            <InstagramIcon className="text-gray-500 hover:text-gray-900 h-6 w-6 fill-current" />
          </a>
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base text-gray-500">
            &copy; 2022 Bana Pane. 35.643.459/0001-98
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
