const navigation = [
  { name: "Estoque", href: "/admin/batches" },
  { name: "Frete", href: "/admin/freight" },
  { name: "Produtos", href: "/admin/products" },
]

const AdminShell = ({ children }: { children: JSX.Element }) => {
  return (
    <div className="bg-white">
      <header>
        <nav
          className="mx-auto flex max-w-7xl items-center justify-center p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>
        </nav>
      </header>

      {children}
    </div>
  )
}

export default AdminShell
