import Link from "next/link"

import { classNames } from "@app/components"

/* import LogoSymbol from "@app/components/svg/g30.svg" */
import LogoHorizontal from "@app/components/svg/logo-horizontal.svg"
import LogoSymbol from "@app/components/svg/logo-symbol.svg"

export const Logo = ({
  height,
  width,
  withText = true,
}: {
  height: string
  width: string
  withText?: boolean
}) => {
  const LogoComponent = withText ? LogoHorizontal : LogoSymbol

  return (
    <div className={classNames(height, width, "flex-shrink-0")}>
      <Link href="/">
        <a>
          <LogoComponent />
        </a>
      </Link>
    </div>
  )
}
