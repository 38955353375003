import Seal from "@app/components/svg/seal-bread.svg"

import { classNames } from "."

const SealBread = ({ pagePath }: { pagePath: string }) => {
  const classes = pagePath === "/" ? "right-1 -bottom-14" : "-top-14 left-1"

  return (
    <div className={classNames("hidden w-48 2xl:block pointer-events-none fixed z-30", classes)}>
      <Seal className="text-black" />
    </div>
  )
}

export default SealBread
