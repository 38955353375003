import isEmpty from "lodash/isEmpty"

import { Cart, CartItem } from "../types"

// Bump this whenever there is a schema change, so items on don't generate bad items
const VERSION = 1
const CART_KEY = "banapane_cart"

const writeCart = (cart: Cart) => {
  cart.date = new Date().toISOString()
  localStorage.setItem(CART_KEY, JSON.stringify(cart))
}

export const addItem = (item: CartItem) => {
  const cart = getCart()

  const existingVariation = cart.items.find((i) => i.sku === item.sku && i.batchId === item.batchId)

  if (existingVariation) {
    existingVariation.amount += item.amount
  } else {
    cart.items.push(item)
  }

  writeCart(cart)
}

export const clearCart = () => {
  localStorage.setItem(CART_KEY, "")
}

export const removeItem = (item: CartItem) => {
  const cart = getCart()
  cart.items = cart.items.filter((i) => i.sku !== item.sku || i.batchId !== item.batchId)
  writeCart(cart)
}

export const setAmountItem = (item: CartItem, amount: number) => {
  const cart = getCart()
  const cartItem = cart.items.find((i) => i.sku === item.sku && i.batchId === item.batchId)

  if (!cartItem) {
    throw new Error("item not found on Local storage")
  }
  cartItem.amount = amount
  writeCart(cart)
}

export const emptyCart = (): Cart => ({
  version: VERSION,
  date: new Date().toISOString(),
  items: [],
})

export const getCart = (): Cart => {
  const storageValue = localStorage.getItem(CART_KEY)
  if (storageValue === null) {
    return emptyCart()
  }

  let cart
  try {
    cart = JSON.parse(storageValue)
  } catch (e) {
    return emptyCart()
  }

  if (isEmpty(cart)) {
    return emptyCart()
  }

  if (cart.version < VERSION) {
    return emptyCart()
  }

  const oneHourAgo = new Date(new Date().getTime() - 60 * 60 * 1000)
  if (new Date(cart.date) < oneHourAgo) {
    return emptyCart()
  }

  return JSON.parse(storageValue)
}
