import { MouseEventHandler } from "react"

const AdminButton = ({
  text,
  type = "submit",
  primary = true,
  onClick = null,
}: {
  text: string
  type?: "submit" | "reset" | "button" | undefined
  primary?: boolean
  onClick?: MouseEventHandler | null
}) => {
  const attrs = onClick !== null ? { onClick } : {}

  const buttonStyle = primary
    ? "rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
    : "text-sm font-semibold leading-6 text-gray-900"

  return (
    <button type={type} className={buttonStyle} {...attrs}>
      {text}
    </button>
  )
}

export default AdminButton
