import isEmpty from "lodash/isEmpty"
import { signIn } from "next-auth/react"
import Image from "next/image"
import { useEffect, useState } from "react"

import GoogleIcon from "@app/components/svg/google.svg"

import Button from "./shared/Button"

const Errors: Record<string, string> = {
  Signin: "Tente logar com uma conta diferente",
  OAuthSignin: "Tente logar com uma conta diferente",
  OAuthCallback: "Tente logar com uma conta diferente",
  OAuthCreateAccount: "Tente logar com uma conta diferente",
  EmailCreateAccount: "Tente logar com uma conta diferente",
  Callback: "Tente logar com uma conta diferente",
  OAuthAccountNotLinked:
    "Para confirmar sua identidade, se autentique com a mesma conta que você usou originalmente.",
  EmailSignin: "Não foi possível enviar o e-mail.",
  CredentialsSignin: "Autenticação falhou. Cheque se os detalhes que você forneceu estão corretos.",
  SessionRequired: "Por favor, se autentique para acessar essa página",
  default: "Não foi possível se autenticar",
}

const SignIn = ({ callbackUrl, error }: { callbackUrl: string; error: string | undefined }) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (error && !isEmpty(error)) {
      const message = Errors[error]
      setErrorMessage(message)
    }
  }, [error])

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="relative mx-auto h-12 w-auto">
            <Image objectFit="contain" layout="fill" src="/assets/symbol.png" alt="" />
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Entre na sua conta
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              onSubmit={async (e) => {
                setLoading(true)
                try {
                  e.preventDefault()
                  const email = document.getElementById("email")! as HTMLInputElement
                  await signIn("email", { email: email.value, callbackUrl })
                } finally {
                  setLoading(false)
                }
              }}
              className="space-y-6"
              action="#"
              method="POST"
            >
              <div>
                {errorMessage && <p className="text-red-400 mb-5">{errorMessage}</p>}
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Endereço de e-mail
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    data-testid="login-email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <Button dataTestId="login-button" loading={loading} text="Entrar" />
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Ou continue com</span>
                </div>
              </div>

              <div className="mt-6">
                <div className="bg-white shadow sm:rounded-lg">
                  <div>
                    <button
                      onClick={() => {
                        signIn("google", { callbackUrl })
                      }}
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
                    >
                      <span className="sr-only">Logar com Google</span>
                      <GoogleIcon className="w-5 h-5 mr-2 text-gray-500 fill-current" />
                      Google
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignIn
