export interface Variation {
  id: string
  sku: string
  price: number
  value: string
  weight: string | null
}

export interface Product {
  id: string
  name: string
  sku: string
  images: string[]
  price: number
  variationType: string
  description: string
  shortDescription: string
  variations: Variation[]
}

export interface StockBalance {
  id?: number
  sku: string
  batchId: string
  batchDate: string
  balance: number
}

export enum BatchDay {
  sunday = 0,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
}

export type BatchId = keyof typeof BatchDay

export interface StockSituation {
  productSku: string
  allowedBatches: BatchId[]
  stocks: Stock[]
}

export interface Stock {
  batchDate: string
  batchId: string
  balance: number
}

export interface AllowedBatch {
  id?: string
  sku: string
  batchIds: BatchId[]
}

export interface Customer {
  id?: string
  email: string
  emailVerified?: Date
  name?: string
  image?: string
  cardId?: string
  documentNumber?: string
  phone?: string
}

export enum DocumentType {
  CPF,
  CNPJ,
}

export interface Document {
  documentType: DocumentType
  documentNumber: string
}
